<template>
    <div class="itemHolder">

        <div class="row" :key="key" v-for="(item, key) of teamUsers">
            <div class="title textNoWrap">{{ getItem(item) }}</div>
            <div class="removeBtn"><button class="remove" @click="remove(key)"><i class="fas fa-trash"></i></button></div>
        </div>

        <form class="itemForm" action="" @submit.prevent="addItem">
            <select v-model="selectedItem">
                <option value="">-- Select user --</option>
                <option :key="key" v-for="(item, key) of excludedUsers" :value="key">{{ item.name }}</option>
            </select>
            <button class="default" type="submit" :disabled="!isValidForm"><i class="fas fa-plus"></i></button>
        </form>
        
    </div>
</template>

<script>
import { mapGetters } from "vuex";
    export default {
        props: ["modelValue"],
        data() {
            return {
                teamUsers: this.modelValue || [],
                selectedItem: ''
            };
        },
        computed: {
            ...mapGetters(["users"]),
            isValidForm() {
                return this.users[this.selectedItem];
            },
            excludedUsers() {
                return this.users.filter(user => {
                    for(const item of this.teamUsers) {
                        if(user._id == item) return false;
                    }
                    return true;
                });
            }
        },
        methods: {
            remove(key) {
                this.teamUsers.splice(key, 1);
                this.selectedItem = '';
            },
            addItem() {
                this.teamUsers.push(this.excludedUsers[this.selectedItem]._id);
                this.selectedItem = '';
            },
            getItem(id) {
                for(const user of this.users) {
                    if(user._id == id) return user.name;
                }
                return 'Unknown';
            }
        },
        mounted() {
            this.$emit("update:modelValue", this.teamUsers);
        },
    };
</script>

<style lang="scss" scoped>

.itemHolder {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background: $bgSecondary;
}

.itemForm {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 40px;
    column-gap: 20px;
    grid-template-rows: 40px;
    padding: 10px 20px;
    border-top: 1px solid $borderColor;
    &:first-child {
        border: 0;
    }
    select, input {
        width: 100%;
        text-align: center;
        background: transparent;
        border: 0;
        padding: 0 10px;
    }
    select {
        text-align: left;
    }
    button {
        padding: 0;
        height: auto;
    }
}



.row {
    display: grid;
    height: 50px;
    align-items: center;
    padding: 0 20px;
    grid-template-columns: minmax(0, 1fr) 20px;
    column-gap: 20px;

    &:nth-child(2n) {
        background: $bg;
    }
    .title {
        font-weight: bold;
    }
    .value {
        text-align: right;
    }
    .removeBtn {
        width: 20px;
        height: 20px;
        .remove {
            width: inherit;
            height: inherit;
            background: transparent;
            border: 0;
            font-size: 10px;
            color: $error;
        }
    }
}
</style>