<template>
    <div class="teamDetails">


        <h2>Team details</h2>
        <div class="groupHolder">
            <div class="row">
                <div class="title">Team name</div>
                <div class="value"><input class="invisibleInput textRight" :class="{ 'error' : errors.name }" type="text" v-model="team.name" placeholder="Team name"></div>
            </div>
        </div>
        
        <h2>Team members</h2>
        <TeamUsers v-model="team.users"/>

        <h2>Team carriers</h2>
        <TeamCarriers v-model="team.carriers"/>

        <button class="default" @click="handleSubmit" :disabled="processing">Save changes</button>

    </div>
</template>

<script>
import TeamUsers from './TeamUsers.vue'
import TeamCarriers from './TeamCarriers.vue'

    export default {
        components: {
           TeamUsers, 
           TeamCarriers
        },
        props: ['item'],
        data() {
            return {
                team: this.item || {},
                errors: {},
                processing: false
            }
        },
        methods: {
            async handleSubmit() {
                this.errors = {};
                this.processing = true;

                await this.$axios.put('/teams', this.team)
                .then(data => {
                    let body = data.data;

                    this.$emit('updated');

                    this.$notify({
                        type: 'info',
                        text: 'Team has been updated'
                    });

                })
                .catch(err => {
                    let response = err.response;

                    if(response && response.data) {
                        let body = response.data;
                        if(body.errors) this.errors = body.errors;
                        else if(body.message) alert(body.message);
                        else alert("Something went wrong. Please, try again!");
                    } else {
                        alert("Something went wrong. Please, try again!");
                    }

                });

                this.processing = false;
            }
        },
    }
</script>

<style lang="scss" scoped>

.groupHolder {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background: $bgSecondary;
}


.row {
    display: grid;
    height: 50px;
    align-items: center;
    padding: 0 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    &:nth-child(2n) {
        background: $bg;
    }
    .title {
        font-weight: bold;
    }
    .value {
        text-align: right;
    }
}

.teamDetails {
    display: grid;
    row-gap: 20px;
    padding-top: 50px;
}

</style>